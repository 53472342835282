import * as React from "react";
import Button, {ButtonProps} from "@/components/ui/button/button";

type NewTabButtonProps = React.HTMLAttributes<HTMLButtonElement> & ButtonProps & {
    url: string | null;
}

export const NewTabButton = React.forwardRef<HTMLButtonElement, NewTabButtonProps>(
    (
        {
            url,
            ...props
        },
        ref
    ) => {
        const openNewTab = () => {
            if (url) {
                window.open(url, "_blank");
            }
        }

        return (
            <Button
                ref={ref}
                onClick={openNewTab}
                className={url ? "" : "opacity-50 cursor-not-allowed"}
                {...props}
            />
        );
    }
);