import * as React from "react";

import PaginationTable, {
    ColumnVisMapperProps,
    ColumnVisMeta,
    CustomFilterMeta
} from "@/components/ui/table/pagination-table/pagination-table";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {FilterMatchMode} from "primereact/api";
import {UseSalesItems} from "@/features/sales/api/get-sales-items";
import {currencyF, numberF, percentF} from "@/utils/formatter";

export const SalesItems = ({
    dateRange,
}: {
    dateRange: Range[];
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesItemsQuery = UseSalesItems({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (salesItemsQuery.isLoading) {
        return <div>Sales Table Loading...</div>;
    }

    if (!salesItemsQuery.data) return null;

    const initialFilters: CustomFilterMeta = {
        component_item: { value: null, matchMode: FilterMatchMode.CONTAINS },
        vendor_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        component_upc: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
        units: { value: null, matchMode: FilterMatchMode.CUSTOM },
        sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
        revenue_share: { value: null, matchMode: FilterMatchMode.CUSTOM },
        amazon_listing_count: { value: null, matchMode: FilterMatchMode.CUSTOM },
    }

    const visMapper: ColumnVisMapperProps = {
        marketplace: ColumnVisMeta.Amz_link,
        detail_link: ColumnVisMeta.Detail_link,
    }

    return (
        <PaginationTable
            data={salesItemsQuery.data.data.map(item => ({
                ...item,
                units: numberF(item.units),
                sales: currencyF(item.sales),
                revenue_share: percentF(item.revenue_share),
            }))}
            columns={salesItemsQuery.data.columns}
            initialFilters={initialFilters}
            visMapper={visMapper}
            dateRange={dateRange}
        />
    )
}