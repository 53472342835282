import {useBrand} from "@/app/main-provider";
import {useAdvertisingOrganicBar} from "@/features/advertising/api/get-advertising-organic-bar";
import {Range} from "react-date-range";
import {MultipleAxisTimeValueBarChart} from "@/components/ui/chart/bar/multiple-axis-bar";
import {echartColors} from "@/styles/echart-styles";

export const AdvertisingOrganicBar = ({
    height = "18.5rem",
}: {
    height?: string;
}) => {
    const { brand } = useBrand();

    const advertisingOrganicBarQuery = useAdvertisingOrganicBar({
        brand,
    });

    if (advertisingOrganicBarQuery.isLoading) {
        return <div>Advertising Organic Bar Loading...</div>;
    }

    if (!advertisingOrganicBarQuery.data) return null;

    return (
        <MultipleAxisTimeValueBarChart
            title={"Organic Summary"}
            labels={[
                { name: "Organic", position: "left" },
                { name: "Ad Sales", position: "right" },
            ]}
            series={[
                advertisingOrganicBarQuery.data.map((row) => [row.date, row.organic]),
                advertisingOrganicBarQuery.data.map((row) => [row.date, row.ad_sales]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
            stacked={true}
        />
    );
}