import {queryOptions, useQuery} from "@tanstack/react-query";

import {InfoResponse, InventoryResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getAssemblyInventory = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}): Promise<InventoryResponse[]> => {
    return api.get(`/assembly/${assembly_item}/inventory`, {
        params: {
            brand,
        }
    })
}

export const getAssemblyInventoryQueryOptions = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "chart", brand, assembly_item],
        queryFn: () => getAssemblyInventory({ brand, assembly_item }),
    })
}

type UseAssemblyInventoryOptions = {
    brand: string;
    assembly_item: string;
    queryConfig?: QueryConfig<typeof getAssemblyInventoryQueryOptions>;
}

export const useAssemblyInventory = ({
    brand,
    assembly_item,
    queryConfig,
}: UseAssemblyInventoryOptions) => {
    return useQuery({
        ...getAssemblyInventoryQueryOptions({ brand, assembly_item }),
        ...queryConfig,
    })
}