import {QueryClient} from "@tanstack/react-query";
import {getProductsItemsQueryOptions} from "@/features/products/api/get-products-items";
import {ProductsItems} from "@/features/products/components/products-items";
import React, {useState} from "react";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {getLatestDay, getPast30d} from "@/utils/date_retriever";

export const productsDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    if (!brand) return null;

    const productsItemsQuery = getProductsItemsQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    });

    const promises = [
        queryClient.getQueryData(productsItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(productsItemsQuery)),
    ] as const;

    const [ productsItems ] = await Promise.all(promises);

    return {
        productsItems,
    }
}

export const ProductsDashboardRoute = () => {
    const { brand } = useBrand();
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: getPast30d,
            endDate: getLatestDay,
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>Products</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <ProductsItems
                dateRange={dateRange}
            />
        </>
    );
}