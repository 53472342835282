import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import ReactECharts from "echarts-for-react";

import { EChartsOption } from "echarts";
import {cn} from "@/utils/cn";
import {CallbackDataParams, TopLevelFormatterParams} from "echarts/types/dist/shared";
import {currencyF, numberF, percentF} from "@/utils/formatter";

const chartVariants = cva(
    "bg-white rounded-xl dark:bg-rigleDark",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type EchartProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof chartVariants> & {
    option: EChartsOption;
    width?: string;
    height: string;
}

const Echarts = React.forwardRef<HTMLDivElement, EchartProps>(
    (
        {
            className,
            variant,
            size,
            children,
            option,
            width = "auto",
            height,
            ...props
        },
        ref
    ) => {
        return (
            <div className={cn(`w-${width} h-${height}`, chartVariants({ variant, size, className }))}>
                <ReactECharts option={option} style={{ width: width, height: height }} />
            </div>
        );
    },
);

export const timeSeriesTooltipFormatter = ({
    params,
    keepTimeFormat,
}: {
    params: TopLevelFormatterParams;
    keepTimeFormat?: boolean;
}) => {
    let tooltipContent = "";

    if (keepTimeFormat) {
        tooltipContent += `${Array.isArray(params) ? params[0].name: params.name}`;
    } else {
        const date = new Date(((Array.isArray(params) ? params[0].value: params.value) as [number, number])[0] + "T00:00:00");
        const date_str = `${date.toLocaleDateString()}`;
        tooltipContent += `
            <div style="margin-bottom: 5px; font-weight: bold;">${date_str}</div>
        `;
    }

    const paramList = !Array.isArray(params) ? [params] : params;
    paramList.forEach((param: CallbackDataParams) => {
        const value = (param.value as [number, number])[1];

        let formattedVal = value.toLocaleString();
        if (param.seriesName && ["Sales", "Spend", "Amount", "Organic", "Ad Sales"].includes(param.seriesName)) {
            formattedVal = currencyF(value);
        }

        tooltipContent += `
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <span style="display: flex; align-items: center;">
                    ${param.marker} ${param.seriesName}
                </span>
                <span style="font-weight: bold; margin-left: 10px;">
                    ${formattedVal}
                </span>
            </div>
        `;
    })

    return tooltipContent;
}

export const CategoryTooltipFormatter = ({
    params,
    usePercent = true,
    scaleFn,
}: {
    params: TopLevelFormatterParams;
    usePercent?: boolean;
    scaleFn?: (x: number) => number;
}) => {
    let tooltipContent = "";
    tooltipContent += ` ${Array.isArray(params) ? params[0].name: params.name}`;

    const paramList = !Array.isArray(params) ? [params] : params;
    paramList.forEach((param: CallbackDataParams) => {
        const value = param.value as number;
        const percent = param.percent as number;

        let formattedVal = numberF(scaleFn ? (value == 0 ? 0 : scaleFn(value)) : value);
        let formattedPercent = percentF(percent / 100);

        tooltipContent += `
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <span style="display: flex; align-items: center;">
                    ${param.marker} ${param.seriesName}
                </span>
                <span style="font-weight: bold; margin-left: 10px;">
                    ${formattedVal} ${usePercent ? `(${formattedPercent})` : ""}
                </span>
            </div>
        `;
    })

    return tooltipContent;
}

export default Echarts;