import {QueryClient} from "@tanstack/react-query";
import {SalesChart} from "@/features/sales/components/sales-chart";
import React, {useState} from "react";
import {getSalesChartQueryOptions} from "@/features/sales/api/get-sales-chart";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {SalesAnnualChart} from "@/features/sales/components/sales-annual-chart";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {SalesItems} from "@/features/sales/components/sales-items";
import {getSalesAnnualChartQueryOptions} from "@/features/sales/api/get-sales-annual-chart";
import {getSalesItemsQueryOptions} from "@/features/sales/api/get-sales-items";
import {SalesCard} from "@/features/sales/components/sales-card";
import {getSalesQueryOptions} from "@/features/sales/api/get-sales";
import {getLatestDay, getPast30d} from "@/utils/date_retriever";

export const salesDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    if (!brand) return null;

    const salesCardQuery = getSalesQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    })

    const salesAnnualChartQuery = getSalesAnnualChartQueryOptions({
        brand: brand,
    })

    const salesChartQuery = getSalesChartQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    });

    const salesItemsQuery = getSalesItemsQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    })

    const promises = [
        queryClient.getQueryData(salesCardQuery.queryKey) ??
            (await queryClient.fetchQuery(salesCardQuery)),
        queryClient.getQueryData(salesAnnualChartQuery.queryKey) ??
            (await queryClient.fetchQuery(salesAnnualChartQuery)),
        queryClient.getQueryData(salesChartQuery.queryKey) ??
            (await queryClient.fetchQuery(salesChartQuery)),
        queryClient.getQueryData(salesItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(salesItemsQuery)),
    ] as const;
    const [salesCard, salesAnnualChart, salesChart, salesItems] = await Promise.all(promises);

    return {
        salesCard,
        salesAnnualChart,
        salesChart,
        salesItems
    }
}

export const SalesDashboardRoute = () => {
    const { brand } = useBrand();
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: getPast30d,
            endDate: getLatestDay,
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>Sales</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <SalesCard
                dateRange={dateRange}
            />
            <div className={"grid grid-cols-2 gap-x-5"}>
                <SalesAnnualChart />
                <SalesChart
                    dateRange={dateRange}
                />
            </div>
            <SalesItems
                dateRange={dateRange}
            />
        </>
    );
}