import {useBrand} from "@/app/main-provider";
import {useAssemblyInfo} from "@/features/assembly/api/get-assembly-info";
import {Card} from "@/components/ui/card/card";
import {Range} from "react-date-range";
import {currencyF, numberF} from "@/utils/formatter";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import React from "react";
import {AssemblyInventoryCard} from "@/features/assembly/components/AssemblyInventoryCard";

export const AssemblyInfoCard = ({
    assembly_item,
    dateRange,
    setDateRange,
}: {
    assembly_item: string;
    dateRange: Range[];
    setDateRange: (dateRange: Range[]) => void;
}) => {
    const { brand } = useBrand();

    const assemblyInfoQuery = useAssemblyInfo({
        brand,
        assembly_item,
    });

    if (assemblyInfoQuery.isLoading) {
        return <div>Assembly Info Card Loading...</div>;
    }

    if (!assemblyInfoQuery.data) return null;

    const info = assemblyInfoQuery.data;

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>{info.display_name}</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>

            <div className={"grid grid-cols-6 gap-x-5"}>
                <div className={"col-span-3 h-full"}>
                    <Card className={"flex flex-row gap-3 h-full text-white font-bold"}>
                        <div className={"flex flex-col text-sm"}>
                            <div className={"mb-2"}>
                                <img src={info.img} alt={"listing image"}
                                     className={"w-[8.78rem] h-[6rem] object-scale-down shadow-2"}/>
                            </div>
                            <a href={info.link} target="_blank" rel="noopener noreferrer" className={"text-blue-500"}>
                                View on Amazon
                            </a>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>ASIN</span>
                                <span>{info.asin}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Vendor Name</span>
                                <span>{info.vendor_name}</span>
                            </div>
                        </div>

                        <div className={"flex flex-col gap-y-5"}>
                            <div className={"flex flex-row gap-x-5"}>
                                <div className={"flex flex-col"}>
                                    <span className={"text-sm text-rigleDarkGrey font-bold"}>Main Category</span>
                                    <span>{`#${info.category_rank} ${info.category}`}</span>
                                </div>
                            </div>
                            <div className={"flex flex-row gap-x-5"}>
                                <div className={"flex flex-col"}>
                                    <span className={"text-sm text-rigleDarkGrey font-bold"}>Category</span>
                                    <span>{`#${numberF(info.subcategory_rank)} ${info.subcategory}`}</span>
                                </div>
                            </div>
                            <div className={"flex flex-row gap-3"}>
                                <div className={"flex flex-col"}>
                                    <span className={"text-sm text-rigleDarkGrey font-bold"}>Buy Box Price</span>
                                    <span className={"text-end"}>{currencyF(info.bb_price)}</span>
                                </div>
                                <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Average Price</span>
                                    <span className={"text-end"}>{currencyF(info.avg_price)}</span>
                                </div>
                            </div>
                            <div className={"flex flex-row -mt-6"}>
                                <div className={"flex flex-col"}>
                                    <span className={"text-sm text-rigleDarkGrey font-bold"}>MAP Price</span>
                                    <span className={"text-end"}>{info.map_price === 0 ? "N/A" : currencyF((info.map_price))}</span>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className={"col-span-3 h-full"}>
                    <AssemblyInventoryCard
                        assembly_item={info.asin}
                    />
                </div>
            </div>
        </>
    );
}