import {QueryClient} from "@tanstack/react-query";
import {getPurchaseChartQueryOptions} from "@/features/purchase/api/get-purchase-chart";
import {getPurchaseItemsQueryOptions} from "@/features/purchase/api/get-purchase-items";
import {PurchaseChart} from "@/features/purchase/components/purchase-chart";
import {PurchaseItems} from "@/features/purchase/components/purchase-items";
import React from "react";
import {useBrand} from "@/app/main-provider";
import SimpleDropdown from "@/components/ui/dropdown/simple-dropdown/simple-dropdown";

export const purchaseDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    const selector = "Quantity"

    if (!brand) return null;

    const purchaseChartQuery = getPurchaseChartQueryOptions({
        brand: brand,
    });

    const purchaseItemQuery = getPurchaseItemsQueryOptions({
        brand: brand,
        selector: selector,
    });

    const promises = [
        queryClient.getQueryData(purchaseChartQuery.queryKey) ??
            (await queryClient.fetchQuery(purchaseChartQuery)),
        queryClient.getQueryData(purchaseItemQuery.queryKey) ??
            (await queryClient.fetchQuery(purchaseItemQuery)),
    ] as const;

    const [purchaseChart, purchaseItems ] = await Promise.all(promises);

    return {
        purchaseChart,
        purchaseItems,
    }
}

export const PurchaseDashboardRoute = () => {
    const { brand } = useBrand();
    const filterOptions = ["Quantity", "Amount"];
    const [filter, setFilter] = React.useState<typeof filterOptions[number]>("Quantity");

    if (!brand) return null;

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl dark:text-white"}>Purchase Forecast</span>
                <SimpleDropdown
                    filter={filter}
                    filterOptions={filterOptions}
                    setFilter={setFilter}
                    className={"w-32"}
                />
            </div>
            <PurchaseChart/>
            <PurchaseItems filter={filter} />
        </>
    );
}