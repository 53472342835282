import {useBrand} from "@/app/main-provider";
import {Range} from "react-date-range";
import {useAdvertising} from "@/features/advertising/api/get-advertising";
import SimpleCard, {CardVisMeta} from "@/components/ui/card/simple-card/simple-card";
import * as React from "react";

export const AdvertisingCard = () => {
    const { brand } = useBrand();

    const advertisingQuery = useAdvertising({
        brand: brand,
    })

    if (advertisingQuery.isLoading) {
        return <div>Advertisement Card Loading...</div>
    }

    if (!advertisingQuery.data) return null;

    const cardVisMapper = {
        "Sales": CardVisMeta.Money,
        "Spend": CardVisMeta.Money,
    }

    return (
        <SimpleCard
            data={advertisingQuery.data}
            cardVisMapper={cardVisMapper}
        />
    );
}