import {usePurchaseChart} from "@/features/purchase/api/get-purchase-chart";
import {useBrand} from "@/app/main-provider";
import {MultipleAxisTimeValueBarChart} from "@/components/ui/chart/bar/multiple-axis-bar";
import {echartColors} from "@/styles/echart-styles";

export const PurchaseChart = ({
    height = "20.8rem",
}:{
    height?: string;
}) => {
    const { brand } = useBrand();

    const purchaseChartQuery = usePurchaseChart({
        brand: brand,
    });

    if (purchaseChartQuery.isLoading) {
        return <div>Purchase Chart Loading...</div>;
    }

    if (!purchaseChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueBarChart
            title={"Purchase Forecast"}
            labels={[
                { name: "Amount", position: "left" },
                { name: "Quantity", position: "right" },
            ]}
            series={[
                purchaseChartQuery.data.summary.map((row) => [row.time, row.amount]),
                purchaseChartQuery.data.summary.map((row) => [row.time, row.quantity]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}