import { cva, type VariantProps} from "class-variance-authority";
import * as React from "react";
import {cn} from "@/utils/cn";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '@/styles/custom-date-range.css';
import {createStaticRanges, DateRangePicker, Range, RangeKeyDict} from 'react-date-range';
import { enUS } from 'date-fns/locale';

const dateRangePickerVariants = cva(
    "translate-y-[3rem] -translate-x-[30.75rem] flex flex-row-reverse rounded-md text-xs font-normal leading-3",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
)

export type DateRangePickerProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dateRangePickerVariants> & {
    dateRange: Range[];
    setDateRange: (dateRange: Range[]) => void;
}

const SimpleDateRangePicker = React.forwardRef<HTMLDivElement, DateRangePickerProps>(
    (
        {
            className,
            variant,
            size,
            children,
            dateRange,
            setDateRange,
            ...props
        },
        ref
    ) => {
        const today = new Date();

        const handleSelect = (ranges: RangeKeyDict) => {
            const { selection } = ranges;
            setDateRange([selection]);
        }

        const startOfWeek = (date: string | number | Date) => {
            const start = new Date(date);
            start.setDate(start.getDate() - start.getDay());
            return start;
        };

        const endOfWeek = (date: string | number | Date) => {
            const end = new Date(date);
            end.setDate(end.getDate() + (6 - end.getDay()));
            return end;
        };

        const startOfMonth = (date: string | number | Date) => {
            const start = new Date(date);
            start.setDate(1);
            return start;
        };

        const endOfMonth = (date: string | number | Date) => {
            const end = new Date(date);
            end.setMonth(end.getMonth() + 1);
            end.setDate(0);
            return end;
        };

        const startOfLastMonth = (date: string | number | Date) => {
            const start = new Date(date);
            start.setMonth(start.getMonth() - 1);
            start.setDate(1);
            return start;
        };

        const endOfLastMonth = (date: string | number | Date) => {
            const end = new Date(date);
            end.setDate(0);
            return end;
        };

        const startOfLastSixMonths = (date: string | number | Date) => {
            const start = new Date(date);
            start.setMonth(start.getMonth() - 6);
            start.setDate(1);
            return start;
        };

        const startOfLastYear = (date: string | number | Date) => {
            const start = new Date(date);
            start.setFullYear(start.getFullYear() - 1);
            start.setMonth(0);
            start.setDate(1);
            return start;
        };

        const endOfLastYear = (date: string | number | Date) => {
            const end = new Date(date);
            end.setFullYear(end.getFullYear() - 1);
            end.setMonth(12);
            end.setDate(0);
            return end;
        };

        const staticRanges = createStaticRanges([
            {
                label: 'This Week',
                range: () => ({
                    startDate: startOfWeek(today),
                    endDate: endOfWeek(today),
                }),
            },
            {
                label: 'Last Week',
                range: () => ({
                    startDate: startOfWeek(new Date(new Date().setDate(today.getDate() - 7))),
                    endDate: endOfWeek(new Date(new Date().setDate(today.getDate() - 7))),
                }),
            },
            {
                label: 'This Month',
                range: () => ({
                    startDate: startOfMonth(today),
                    endDate: endOfMonth(today),
                }),
            },
            {
                label: 'Last Month',
                range: () => ({
                    startDate: startOfLastMonth(today),
                    endDate: endOfLastMonth(today),
                }),
            },
            {
                label: 'Last 6 Months',
                range: () => ({
                    startDate: startOfLastSixMonths(today),
                    endDate: endOfMonth(today),
                }),
            },
            {
                label: 'Last Year',
                range: () => ({
                    startDate: startOfLastYear(today),
                    endDate: endOfLastYear(today),
                }),
            },
        ]);

        return (
            <DateRangePicker
                className={cn("", dateRangePickerVariants({variant, size, className}))}
                ranges={dateRange}
                onChange={handleSelect}
                locale={enUS}
                maxDate={new Date(new Date().setDate(today.getDate() - 2))}
                minDate={new Date("2024-01-02")}
                staticRanges={staticRanges}
                showPreview={true}
                moveRangeOnFirstSelection={false}
            />
        )
    }
);

export default SimpleDateRangePicker;