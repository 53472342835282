import {useBrand} from "@/app/main-provider";
import {useAssemblyInventory} from "@/features/assembly/api/get-assembly-inventory";
import {Card} from "@/components/ui/card/card";
import {numberF} from "@/utils/formatter";
import React from "react";
import {type} from "@testing-library/user-event/dist/type";

export const AssemblyInventoryCard = ({
    assembly_item,
}: {
    assembly_item: string;
}) => {
    const { brand } = useBrand();

    const assemblyInventoryQuery = useAssemblyInventory({
        brand,
        assembly_item,
    });

    if (assemblyInventoryQuery.isLoading) {
        return <div>Assembly Inventory Card Loading...</div>;
    }

    if (!assemblyInventoryQuery.data) return null;

    const info = assemblyInventoryQuery.data[0];

    return (
        <>
            <Card className={"flex flex-row gap-10 h-full text-white font-bold"}>
                <div className={"flex flex-col gap-y-3"}>
                    <div>
                        Amazon Inventory
                    </div>
                    <div className={"flex gap-x-3"}>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Total</span>
                                <span className={"text-end"}>{numberF(info.inventory_total)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Available</span>
                                <span className={"text-end"}>{numberF(info.inventory_available)}</span>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Inbound</span>
                                <span className={"text-end"}>{numberF(info.inventory_inbound)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Reserved</span>
                                <span className={"text-end"}>{numberF(info.inventory_reserved)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Unfulfillable</span>
                                <span className={"text-end"}>{numberF(info.inventory_unfulfillable)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>Inventory Researching</span>
                                <span className={"text-end"}>{numberF(info.inventory_researching)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col gap-y-3"}>
                    <div>
                        Inventory Age
                    </div>
                    <div className={"flex gap-x-3"}>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>0-30</span>
                                <span className={"text-end"}>{numberF(info.inv_age_0_to_30_days)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>31-60</span>
                                <span className={"text-end"}>{numberF(info.inv_age_31_to_60_days)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>61-90</span>
                                <span className={"text-end"}>{numberF(info.inv_age_61_to_90_days)}</span>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>91-180</span>
                                <span className={"text-end"}>{numberF(info.inv_age_91_to_180_days)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>181-270</span>
                                <span className={"text-end"}>{numberF(info.inv_age_181_to_270_days)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>271-365</span>
                                <span className={"text-end"}>{numberF(info.inv_age_271_to_365_days)}</span>
                            </div>
                            <div className={"flex flex-col"}>
                                <span className={"text-sm text-rigleDarkGrey font-bold"}>365+</span>
                                <span className={"text-end"}>{numberF(info.inv_age_365_plus_days)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}