import {QueryClient} from "@tanstack/react-query";
import {getComponentQueryOptions} from "@/features/component/api/get-component";
import {LoaderFunctionArgs, useParams, useSearchParams} from "react-router-dom";
import {getComponentChartQueryOptions} from "@/features/component/api/get-component-chart";
import {useBrand} from "@/app/main-provider";
import React, {useEffect, useState} from "react";
import {Range} from "react-date-range";
import {ComponentCard} from "@/features/component/components/component-card";
import {ComponentChart} from "@/features/component/components/component-chart";
import {ComponentItems} from "@/features/component/components/component-items";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {ComponentOpenPo} from "@/features/component/components/component-open-po";
import {ComponentPurchaseForecasting} from "@/features/component/components/component-purchase-forecasting";
import {getComponentPurchaseForecastingQueryOptions} from "@/features/component/api/get-component-purchase-forecasting";
import {getComponentOpenPoQueryOptions} from "@/features/component/api/get-component-open-po";
import {getLatestDay, getPast30d} from "@/utils/date_retriever";
import {getComponentItemsQueryOptions} from "@/features/component/api/get-component-items";
import {Card, CardBody, CardHeader} from "@/components/ui/card/card";

export const componentDashboardLoader = (queryClient: QueryClient, brandParam: string) => async ({ params, request }: LoaderFunctionArgs) => {
    const productId = params.productId || "";
    const url = new URL(request.url);
    const brand = url.searchParams.get("brand") || brandParam;

    if (!brand || !productId) return null;
    const componentQuery = getComponentQueryOptions({
        brand: brand,
        component_item: productId,
    });

    const componentChartQuery = getComponentChartQueryOptions({
        brand: brand,
        component_item: productId,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    });

    const componentPurchaseForecastingQuery = getComponentPurchaseForecastingQueryOptions({
        brand: brand,
        component_item: productId,
    });

    const componentItemsQuery = getComponentItemsQueryOptions({
        brand: brand,
        component_item: productId,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    });

    const componentOpenPoQuery = getComponentOpenPoQueryOptions({
        brand: brand,
        component_item: productId,
    })

    const promises = [
        queryClient.getQueryData(componentQuery.queryKey) ??
            (await queryClient.fetchQuery(componentQuery)),
        queryClient.getQueryData(componentChartQuery.queryKey) ??
            (await queryClient.fetchQuery(componentChartQuery)),
        queryClient.getQueryData(componentPurchaseForecastingQuery.queryKey) ??
            (await queryClient.fetchQuery(componentPurchaseForecastingQuery)),
        queryClient.getQueryData(componentItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(componentItemsQuery)),
        queryClient.getQueryData(componentOpenPoQuery.queryKey) ??
            (await queryClient.fetchQuery(componentOpenPoQuery)),
    ] as const;

    const [ component, componentChart, componentPurchaseForecasting, componentItems, componentOpenPo ] = await Promise.all(promises);

    return {
        component,
        componentChart,
        componentPurchaseForecasting,
        componentItems,
        componentOpenPo,
    }
}

export const ComponentDashboardRoute = () => {
    const { productId = "" } = useParams();
    const { brand, setBrand } = useBrand();
    const [searchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: getPast30d,
            endDate: getLatestDay,
            key: "selection",
        }]
    )

    useEffect(() => {
        const brandParam = searchParams.get("brand");
        if (brandParam) {
            setBrand(brandParam);
        }
    }, [searchParams, setBrand]);

    if (!brand) return null;

    return (
        <>
            <ComponentCard
                component_item={productId}
                dateRange={dateRange}
                setDateRange={setDateRange}
            />
            <div className={"grid grid-cols-2 gap-x-5"}>
                <ComponentChart
                    component_item={productId}
                    dateRange={dateRange}
                />
                <ComponentPurchaseForecasting
                    component_item={productId}
                />
            </div>
            <ComponentItems
                component_item={productId}
                dateRange={dateRange}
            />
            <Card className={"row-span-3 col-span-2 h-full"}>
                <CardHeader>Open Purchase Orders</CardHeader>
                <CardBody>
                    <ComponentOpenPo
                        component_item={productId}
                    />
                </CardBody>
            </Card>
        </>
    );
}