import {queryOptions, useQuery} from "@tanstack/react-query";

import {InfoResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getAssemblyInfo = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}): Promise<InfoResponse> => {
    return api.get(`/assembly/${assembly_item}/info`, {
        params: {
            brand,
        }
    })
}

export const getAssemblyInfoQueryOptions = ({
    brand,
    assembly_item,
}: {
    brand: string;
    assembly_item: string;
}) => {
    return queryOptions({
        queryKey: ["assembly", "info", brand, assembly_item],
        queryFn: () => getAssemblyInfo({ brand, assembly_item }),
    })
}

type UseAssemblyInfoOptions = {
    brand: string;
    assembly_item: string;
    queryConfig?: QueryConfig<typeof getAssemblyInfoQueryOptions>;
}

export const useAssemblyInfo = ({
    brand,
    assembly_item,
    queryConfig,
}: UseAssemblyInfoOptions) => {
    return useQuery({
        ...getAssemblyInfoQueryOptions({ brand, assembly_item }),
        ...queryConfig,
    })
}