
export const currencyF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { style = 'currency', currency = 'USD', minimumFractionDigits = 2, maximumFractionDigits = 2 } = options;

    return new Intl.NumberFormat('en-US', {
        style,
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const numberF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { minimumFractionDigits = 0, maximumFractionDigits = 0 } = options;

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}

export const percentF = (value: number | bigint, options: Intl.NumberFormatOptions = {}) => {
    const { style = 'percent', minimumFractionDigits = 2, maximumFractionDigits = 2 } = options;

    return new Intl.NumberFormat('en-US', {
        style,
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(value);
}