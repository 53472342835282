import {useBrand} from "@/app/main-provider";
import {Range} from "react-date-range";
import {useAssemblyAdvertisingChart} from "@/features/assembly/api/get-assembly-advertising-chart";
import {MultipleAxisTimeValueLineChart} from "@/components/ui/chart/line/multiple-axis-line";
import {echartColors} from "@/styles/echart-styles";

export const AssemblyAdvertisingChart = ({
    assembly_item,
    dateRange,
    height = "21.4rem",
}: {
    assembly_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const assemblyAdvertisingChartQuery = useAssemblyAdvertisingChart({
        brand,
        assembly_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (assemblyAdvertisingChartQuery.isLoading) {
        return <div>Assembly Advertising Charts Loading...</div>;
    }

    if (!assemblyAdvertisingChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueLineChart
            title={"Advertising Summary"}
            labels={[
                { name: "Sales", position: "right" },
                { name: "ROAS", position: "left", offset: 30 },
                { name: "Spend", position: "left" },
            ]}
            series={[
                assemblyAdvertisingChartQuery.data.map(row => [row.date, row.total_ad_sales]),
                assemblyAdvertisingChartQuery.data.map(row => [row.date, row.roas]),
                assemblyAdvertisingChartQuery.data.map(row => [row.date, row.total_ad_spend]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
                echartColors.white,
            ]}
        />
    );
}