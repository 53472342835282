import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {User} from "lucide-react";
import {useLogout} from "@/lib/auth";
import {cn} from "@/utils/cn";

const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type UserDropdownProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof dropdownVariants> & {
}

const UserDropdown = React.forwardRef<HTMLDivElement, UserDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            ...props
        },
        ref
    ) => {
        const logout = useLogout();
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu ref={dropdownRef} className={cn("mr-20", { variant, size, className })} {...props}>
                <DropdownTrigger
                    aria-expanded={isOpen}
                    onClick={toggleDropdown}
                    variant={"icon"}
                    size={"icon"}
                >
                    <User className="size-6 rounded-full" />
                </DropdownTrigger>

                {
                    isOpen ? (
                        <DropdownMenuContent className={"w-40 -ml-24"}>
                            <DropdownMenuItem className={"text-start pointer-events-none"}>
                                First Name: {localStorage.getItem("first_name") || ""}
                            </DropdownMenuItem>
                            <DropdownMenuItem className={"text-start pointer-events-none"}>
                                Last Name: {localStorage.getItem("last_name") || ""}
                            </DropdownMenuItem>
                            <DropdownMenuItem className={"text-start pointer-events-none"}>
                                Email: {localStorage.getItem("email") || ""}
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => logout.mutate({})}>
                                Log Out
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    ) : null
                }

            </DropdownMenu>
        );
    }
)

export default UserDropdown;