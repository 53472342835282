import {QueryClient} from "@tanstack/react-query";
import React, {useState} from "react";
import {getAdvertisingChartQueryOptions} from "@/features/advertising/api/get-advertising-chart";
import {getAdvertisingItemsQueryOptions} from "@/features/advertising/api/get-advertising-items";
import {useBrand} from "@/app/main-provider";
import {Range} from "react-date-range";
import {AdvertisingCard} from "@/features/advertising/components/advertising-card";
import {AdvertisingChart} from "@/features/advertising/components/advertising-chart";
import {AdvertisingItems} from "@/features/advertising/components/advertising-items";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import {AdvertisingFunnel} from "@/features/advertising/components/advertising-funnel";
import {AdvertisingOrganicBar} from "@/features/advertising/components/advertising-organic-bar";
import {getLatestDay, getPast30d} from "@/utils/date_retriever";
import {getAdvertisingQueryOptions} from "@/features/advertising/api/get-advertising";
import {getAdvertisingOrganicBarQueryOptions} from "@/features/advertising/api/get-advertising-organic-bar";
import {getAdvertisingFunnnelQueryOptions} from "@/features/advertising/api/get-advertising-funnel";

export const advertisingDashboardLoader = (queryClient: QueryClient, brand: string) => async () => {
    if (!brand) return null;
    const advertisingQuery = getAdvertisingQueryOptions({
        brand: brand,
    });

    const advertisingChartQuery = getAdvertisingChartQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    });

    const advertisingOrganicBarQuery = getAdvertisingOrganicBarQueryOptions({
        brand: brand,
    })

    const advertisingFunnelQuery = getAdvertisingFunnnelQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    })

    const advertisingItemsQuery = getAdvertisingItemsQueryOptions({
        brand: brand,
        period: "Custom",
        startDate: getPast30d?.toISOString().split("T")[0],
        endDate: getLatestDay?.toISOString().split("T")[0],
    });

    const promises = [
        queryClient.getQueryData(advertisingQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingQuery)),
        queryClient.getQueryData(advertisingChartQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingChartQuery)),
        queryClient.getQueryData(advertisingOrganicBarQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingOrganicBarQuery)),
        queryClient.getQueryData(advertisingFunnelQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingFunnelQuery)),
        queryClient.getQueryData(advertisingItemsQuery.queryKey) ??
            (await queryClient.fetchQuery(advertisingItemsQuery)),
    ] as const;

    const [advertisingCard, advertisingChart, advertisingOrganicBar, advertisingFunnel, advertisingItems] = await Promise.all(promises);

    return {
        advertisingCard,
        advertisingChart,
        advertisingOrganicBar,
        advertisingFunnel,
        advertisingItems,
    }
}

export const AdvertisingDashboardRoute = () => {
    const { brand } = useBrand();
    const [dateRange, setDateRange] = useState<Range[]>(
        [{
            startDate: getPast30d,
            endDate: getLatestDay,
            key: "selection",
        }]
    );

    if (!brand) return null;

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>Advertising</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <AdvertisingCard />
            <AdvertisingChart
                dateRange={dateRange}
            />
            <div className={"grid grid-cols-6 gap-x-5"}>
                <div className={"col-span-4"}>
                    <AdvertisingOrganicBar />
                </div>
                <div className={"col-span-2"}>
                    <AdvertisingFunnel
                        dateRange={dateRange}
                    />
                </div>
            </div>
            <AdvertisingItems
                dateRange={dateRange}
            />
        </>
    );
}