import * as React from "react";
import Echarts, {EchartProps, timeSeriesTooltipFormatter} from "@/components/ui/chart/echarts";
import {echartColors, echartColorsProducer} from "@/styles/echart-styles";
import {TimeValueLineData} from "@/components/ui/chart/line/one-axis-line";
import {multipleAxisLabel} from "@/components/ui/chart/line/multiple-axis-line";

type MultipleAxisTimeValueBarProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string;
    labels: multipleAxisLabel[];
    series: TimeValueLineData[][];
    width?: string;
    height: string;
    colors?: string[];
    stacked?: boolean;
}

const MultipleAxisTimeValueBarChart = React.forwardRef<HTMLDivElement, MultipleAxisTimeValueBarProps>(
    (
        {
            className,
            children,
            title,
            labels,
            series,
            width,
            height,
            colors = echartColorsProducer,
            stacked = false,
            ...props
        },
        ref
    ) => {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const hasData = series.some(serie => serie && serie.length > 0);

        const bar_graph: EchartProps["option"] = {
            color: colors,
            animation: false,
            grid: {
                left: "10%",
                right: "10%",
            },
            legend: hasData ? {
                data: labels,
                bottom: "15",
                borderRadius: 4,
                itemWidth: 16,
                itemHeight: 16,
                textStyle: {
                    color: isDarkMode ? echartColors.white : echartColors.black,
                    fontWeight: "bold",
                }
            }: {
                show: false,
            },
            title: {
                text: title,
                left: "20",
                top: "15",
                textStyle: {
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    fontSize: 14,
                }
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => timeSeriesTooltipFormatter({params, keepTimeFormat: true})
            },
            xAxis: {
                type: 'category',
                axisTick: {
                    show: false,
                    alignWithLabel: true
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    formatter: (value: string) => value.substring(0, 3),
                },
                data: series[0].map((row, index) => row[0])
            },
            yAxis: labels
                .filter((_, index) => !(stacked && index > 0))
                .map((label, index) => {
                    return (
                        {
                            type: "value",
                            position: label.position,
                            offset: label.offset,
                            alignTicks: true,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: colors[index],
                                }
                            },
                            axisLabel: {
                                formatter: ["Sales", "Amount", "Organic", "Ad Sales"].includes(labels[index].name) ? "${value}" : "{value}",
                            },
                            splitLine: {
                                lineStyle: {
                                    type: 'dashed',
                                    color: isDarkMode ? echartColors.greyLine : echartColors.silverLine,
                                    width: 1,
                                }
                            }
                        }
                    );
            }),
            series: series.map((serie, index) => {
                return (
                    {
                        data: serie.map((row, index) => [row[0], row[1]]),
                        name: labels[index].name,
                        type: "bar",
                        ...(stacked ? {
                            stack: "Ads",
                            emphasis: {
                                focus: 'series'
                            },
                        }: {
                            yAxisIndex: index,
                        }),
                        markLine: index === 0 ? {
                            data: serie.map(row => (
                                {
                                    name: row[0],
                                    xAxis: row[0].substring(0, row[0].indexOf(" ")) === "January" ? row[0] : "",
                                }
                            )),
                            lineStyle: {
                                type: "dotted",
                                color: "red",
                                width: 2,
                            },
                            label: {
                                show: true,
                                formatter: "Year Change",
                                color: "white",
                            },
                            symbol: 'none',
                        } : {},
                    }
                );
            }),
            graphic: hasData ? [] : [{
                type: 'text',
                left: 'center',
                top: 'middle',
                style: { text: "No data available", fontSize: 20, fill: "#999" },
            }],
        }

        return (
            <Echarts
                option={bar_graph}
                width={width}
                height={height}
            />
        );
    }
);

export {
    MultipleAxisTimeValueBarChart
};