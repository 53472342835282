import {QueryClient} from "@tanstack/react-query";
import {createBrowserRouter} from "react-router-dom";
import {ProtectedRoute} from "@/lib/auth";
import {AppRoot, AuthRoot} from "@/app/routes/app/root";
import React from "react";
import {salesDashboardLoader} from "@/app/routes/app/sales";
import {productsDashboardLoader} from "@/app/routes/app/products";
import {purchaseDashboardLoader} from "@/app/routes/app/purchase";
import {componentDashboardLoader} from "@/app/routes/app/component";
import {advertisingDashboardLoader} from "@/app/routes/app/advertise";
import {assemblyDashboardLoader} from "@/app/routes/app/assembly";

export const createRouter = (queryClient: QueryClient, brand: string, setBrand: (brand: string) => void) =>
    createBrowserRouter([
        {
            path: '/',
            lazy: async () => {
                const { LandingRoute } = await import('./landing');
                return { Component: LandingRoute }
            },
        },
        {
            path: '/auth',
            element: (
                <AuthRoot />
            ),
            children: [
                {
                    path: 'register',
                    lazy: async () => {
                        const { RegisterRoute } = await import('./auth/register');
                        return { Component: RegisterRoute }
                    },
                },
                {
                    path: 'login',
                    lazy: async () => {
                        const { LoginRoute } = await import('./auth/login');
                        return { Component: LoginRoute }
                    },
                },
                {
                    path: 'reset-password',
                    lazy: async () => {
                        const { ResetPasswordRoute } = await import('./auth/reset-password');
                        return { Component: ResetPasswordRoute }
                    },
                },
                {
                    path: 'reset-complete',
                    lazy: async () => {
                        const { ResetCompleteRoute } = await import('./auth/reset-complete');
                        return { Component: ResetCompleteRoute }
                    },
                },
                {
                    path: 'forgot-password',
                    lazy: async () => {
                        const { ForgotPasswordRoute } = await import('./auth/forgot-password');
                        return { Component: ForgotPasswordRoute }
                    },
                },
                {
                    path: 'invitation-complete',
                    lazy: async () => {
                        const { InvitationCompleteRoute } = await import('./auth/invitation-complete');
                        return { Component: InvitationCompleteRoute }
                    },
                },
                {
                    path: 'contact-us',
                    lazy: async () => {
                        const { ContactUsRoute } = await import('./auth/contact-us');
                        return { Component: ContactUsRoute }
                    },
                },
                {
                    path: 'contact-submit',
                    lazy: async () => {
                        const { ContactSubmitRoute } = await import('./auth/contact-submit');
                        return { Component: ContactSubmitRoute }
                    },
                },
                {
                    path: '',
                    lazy: async () => {
                        const { LoginRoute } = await import('./auth/login');
                        return { Component: LoginRoute }
                    },
                },
            ],
        },
        {
            path: '/app',
            element: (
                <ProtectedRoute>
                    <AppRoot />
                </ProtectedRoute>
            ),
            children: [
                {
                    path: 'sales',
                    lazy: async () => {
                        const { SalesDashboardRoute } = await import(
                            './app/sales'
                            );
                        return { Component: SalesDashboardRoute };
                    },
                    loader: salesDashboardLoader(queryClient, brand),
                },
                {
                    path: 'products',
                    lazy: async () => {
                        const { ProductsDashboardRoute } = await import(
                            './app/products'
                            );
                        return { Component: ProductsDashboardRoute };
                    },
                    loader: productsDashboardLoader(queryClient, brand),
                },
                {
                    path: 'component/:productId',
                    lazy: async () => {
                        const { ComponentDashboardRoute } = await import(
                            './app/component'
                            );
                        return { Component: ComponentDashboardRoute };
                    },
                    loader: componentDashboardLoader(queryClient, brand),
                },
                {
                    path: 'assembly/:assemblyId',
                    lazy: async () => {
                        const { AssemblyDashboardRoute } = await import(
                            './app/assembly'
                            );
                        return { Component: AssemblyDashboardRoute };
                    },
                    loader: assemblyDashboardLoader(queryClient, brand),
                },
                {
                    path: 'purchase',
                    lazy: async () => {
                        const { PurchaseDashboardRoute } = await import(
                            './app/purchase'
                            );
                        return { Component: PurchaseDashboardRoute };
                    },
                    loader: purchaseDashboardLoader(queryClient, brand),
                },
                {
                    path: 'advertising',
                    lazy: async () => {
                        const { AdvertisingDashboardRoute } = await import (
                            './app/advertise'
                            );
                        return { Component: AdvertisingDashboardRoute };
                    },
                    loader: advertisingDashboardLoader(queryClient, brand),
                },
                {
                    path: '',
                    lazy: async () => {
                        const { HomeDashboardRoutes } = await import(
                            './app/dashboard'
                            );
                        return { Component: HomeDashboardRoutes };
                    }
                },
            ]
        },
        {
            path: '*',
            lazy: async () => {
                const { NotFoundRoute } = await import('./not-found');
                return { Component: NotFoundRoute }
            },
        },
    ]);
