import * as React from "react";
import {useRef, useState} from "react";

import {cva, VariantProps} from "class-variance-authority";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {FilterMatchMode, FilterService} from "primereact/api";

import {AdListData, Columns, ProductDetailLinkData, ProductsListData} from "@/types";
import {cn} from "@/utils/cn";
import {Toolbar} from "primereact/toolbar";
import Button from "@/components/ui/button/button";
import {NewTabButton} from "@/components/ui/button/newtab-button/newtab-button";
import {env} from "@/config/env";
import {useBrand} from "@/app/main-provider";
import {useLocation} from "react-router-dom";
import {
    DataTableNumberFilterBox,
    DataTableRangeFilterBox,
    DataTableSearchBar,
    DataTableSortAscendingIcon,
    DataTableSortDescendingIcon,
    DataTableSortIcon
} from "@/components/ui/table/datatable";
import {Tooltip} from "primereact/tooltip";
import {Range} from "react-date-range";
import ViewDetailIcon from "@/assets/icons/button/detail-icon.svg";
import AmazonIcon from "@/assets/icons/button/amazon-icon.svg";
import ReloadIcon from "@/assets/icons/table/reload.svg";
import DownloadIcon from "@/assets/icons/table/download.svg";

const paginationTableVariants = cva(
    "rounded-xl bg-white dark:bg-rigleDark w-full",
    {
        variants: {
            variant: {
                default: "",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type FilterProps = {
    value: any;
    matchMode: FilterMatchMode;
};

export type CustomFilterMeta = {
    [key: string]: FilterProps;
}

export enum ColumnVisMeta {
    Image,
    Amz_link,
    Merge_toAmzLink,
    Detail_link,
    Merge_toImage,
    Long_text,
    Highlight,
    Void,
}

export type ColumnVisMapperProps = {
    [key: string]: ColumnVisMeta;
}

type DataSchema = ProductsListData | AdListData | ProductDetailLinkData | object;

export type ProductTableProps = React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof paginationTableVariants> & {
    data: DataSchema[];
    columns: Columns[];
    initialFilters: CustomFilterMeta;
    visMapper: ColumnVisMapperProps;
    dateRange?: Range[];
    noHeader?: boolean;
    maxRow?: number;
    emptyMessage?: string;
};

const PaginationTable = React.forwardRef<HTMLDivElement, ProductTableProps>(
    (
        {
            className,
            variant,
            size,
            children,
            data,
            columns,
            initialFilters,
            visMapper,
            dateRange,
            noHeader = false,
            maxRow = 10,
            emptyMessage = "Data takes up to 30 days to load. Thank you for your patience.",
            ...props
        },
        ref
    ) => {
        Object.entries(initialFilters).forEach(([key, value]) => {
            if (value.matchMode == FilterMatchMode.CUSTOM) {
                FilterService.register(`custom_${key}`, (value, filters) => filterByRange(value, filters));
            }
        })
        initialFilters = {
            ...initialFilters,
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        }

        const location = useLocation();
        const [filters, setFilters] = useState<CustomFilterMeta>(initialFilters);
        const dt = useRef<DataTable<DataSchema[]>>(null);
        const {brand} = useBrand();

        const fieldColNameMapper = Object.keys(data.length === 0 ? [] : data[0]).reduce((mapper, val, index) => {
            mapper[val] = columns[index].name;
            mapper[columns[index].name] = val;
            return mapper;
        }, {} as { [key: string]: string });

        const colVisMapper = Object.entries(visMapper).reduce((mapper, [key, value]) => {
            mapper[key] = value;
            mapper[value] = key;
            return mapper;
        }, {} as { [key: string]: string | ColumnVisMeta });

        const exportCSV = () => {
            dt.current?.exportCSV();
        };

        return (
            <div className={"datatable-filter w-full"}>
                <div ref={ref} className={cn(paginationTableVariants({variant, size, className}))} {...props} >
                    {
                        (Object.keys(filters).length === 1 && "global" in filters) ?
                            null
                            :
                            (
                                <Toolbar
                                    className="rounded-t-[14px] rounded-b-none border-t-0 border-x-0 border-[#EBEBEB] bg-white dark:bg-rigleDark dark:border-rigleGreyLine"
                                    start={() => exportToolbarTemplate({filters, setFilters, fieldColNameMapper})}
                                    end={() => resetFilterButton({initialFilters, setFilters})}
                                />
                            )
                    }

                    <DataTable
                        ref={dt}
                        className={""}
                        value={data}
                        paginator={data.length > maxRow}
                        paginatorTemplate={"CurrentPageReport JumpToPageInput FirstPageLink PrevPageLink NextPageLink LastPageLink"}
                        currentPageReportTemplate={"{currentPage} of {totalPages}"}
                        rows={maxRow}
                        header={!noHeader && DataTableHeader({filters, setFilters, exportCSV, dateRange})}
                        tableStyle={{minWidth: '30rem'}}
                        filters={filters}
                        globalFilterFields={Object.keys(data.length === 0 ? [] : data[0]).filter(k => k !== "hyperlink")}
                        emptyMessage={() => emptyTableContent(emptyMessage)}
                        exportFilename={location.pathname.replace("/app/", "")}
                        sortIcon={customSortIcon}
                    >
                        {Object.entries(data.length === 0 ? [] : data[0]).map(([k, v], index) => {
                            if (
                                colVisMapper[k] === ColumnVisMeta.Void ||
                                colVisMapper[k] === ColumnVisMeta.Merge_toImage ||
                                colVisMapper[k] === ColumnVisMeta.Amz_link
                            ) {
                                return null
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Image) {
                                return (
                                    <Column
                                        header={fieldColNameMapper[k]}
                                        field={k}
                                        body={(product: DataSchema) => imageBodyTemplate({product, colVisMapper})}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Merge_toAmzLink) {
                                const link = colVisMapper[ColumnVisMeta.Amz_link]

                                return (
                                    <Column
                                        field={k}
                                        body={(product: any) => (
                                            <NewTabButton
                                                url={product[link]}
                                                icon={<img src={AmazonIcon} alt={"Amazon Link Icon"} />}
                                                variant="amazonLink"
                                                size={"tableLink"}
                                            >
                                                {product[k]}
                                            </NewTabButton>
                                        )}
                                        sortable
                                        header={fieldColNameMapper[k]}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Detail_link) {
                                return (
                                    <Column
                                        field={k}
                                        body={(product: any) => (
                                            <NewTabButton
                                                url={product[k] && `${env.APP_URL}app/${product[k]}?brand=${brand}`}
                                                icon={<img src={ViewDetailIcon} alt={"View Detail Icon"}/>}
                                                variant={"detailLink"}
                                                size={"tableLink"}
                                            >
                                                Details
                                            </NewTabButton>
                                        )}
                                        header={fieldColNameMapper[k]}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Long_text) {
                                return (
                                    <Column
                                        header={fieldColNameMapper[k]}
                                        field={k}
                                        sortable
                                        style={{ minWidth: '10rem' }}
                                    />
                                );
                            }

                            if (colVisMapper[k] === ColumnVisMeta.Highlight) {
                                return (
                                    <Column
                                        header={fieldColNameMapper[k]}
                                        field={k}
                                        sortable
                                        className={"text-rigleYellow"}
                                        headerClassName={"!text-rigleYellow"}
                                    />
                                );
                            }

                            return (
                                <Column
                                    header={fieldColNameMapper[k]}
                                    field={k}
                                    sortable
                                />
                            );
                        })}
                    </DataTable>
                </div>
            </div>
        )
    }
);

const emptyTableContent = (message: string) => (
    <div className="flex justify-center items-center h-96 relative">
        {/* Blurred Background */}
        <div className="absolute inset-0 bg-gradient-to-r from-yellow-400 to-green-400 blur-lg"></div>

        {/* Unblurred Content */}
        <div className="relative z-10 p-10 bg-white bg-opacity-80 rounded-lg shadow-lg text-center">
            <p className="text-3xl font-bold text-green-700 mb-4">No Products Found</p>
            <p className="text-lg text-yellow-600">{message}</p>
        </div>
    </div>
)

const filterByRange = (value: number, filters: any) => {
    // @ts-ignore
    const [from, to] = filters ?? [null, null];
    if (from === null && to === null) return true;
    if (from !== null && to === null) return from <= value;
    if (from === null && to !== null) return value <= to;
    return from <= value && value <= to;
}

const resetFilters = ({
    initialFilters,
    setFilters,
}: {
    initialFilters: CustomFilterMeta;
    setFilters: (filters: CustomFilterMeta) => void;
}) => {
    setFilters(initialFilters);
}

const resetFilterButton = ({
    initialFilters,
    setFilters,
}: {
    initialFilters: CustomFilterMeta;
    setFilters: (filters: CustomFilterMeta) => void;
}) => (
    <Button
        className={"ml-auto col-start-6 flex justify-center text-[#696969] dark:text-rigleDarkGrey text-[#696969] dark:text-rigleDarkGrey translate-y-0.5 font-bold"}
        icon={<img src={ReloadIcon} alt={"reload_icon"} /> }
        onClick={() => resetFilters({initialFilters, setFilters})}
    >
        Reset filter
    </Button>
)

const exportToolbarTemplate = ({
    filters,
    setFilters,
    fieldColNameMapper,
}: {
    filters: CustomFilterMeta;
    setFilters: (filters: (prevFilter: CustomFilterMeta) => CustomFilterMeta) => void;
    fieldColNameMapper: { [key: string]: string };
}) => {
    return (
        <div className={"grid grid-cols-6 gap-4"}>
            {Object.entries(filters).map(([key, filter], index) => {
                if (key === "global") return null;

                if (filter.matchMode === FilterMatchMode.CUSTOM) {
                    return (
                        <DataTableRangeFilterBox
                            filters={filters}
                            setFilters={setFilters}
                            filterKey={key}
                            filter={filter}
                            columnName={fieldColNameMapper[key]}
                            key={key + index}
                        />
                    );
                }

                return (
                    <DataTableNumberFilterBox
                        filters={filters}
                        setFilters={setFilters}
                        filterKey={key}
                        filter={filter}
                        columnName={fieldColNameMapper[key]}
                        key={key + index}
                    />
                );
            })}

        </div>
    );
};

const DataTableHeader = ({
    filters,
    setFilters,
    exportCSV,
    dateRange,
}: {
    filters: CustomFilterMeta;
    setFilters: (filters: (prevFilter: CustomFilterMeta) => CustomFilterMeta) => void;
    exportCSV: () => void;
    dateRange: Range[] | undefined;
}) => {
    // let dateRangeText: string = "";
    // if (dateRange) {
    //     const { startDate, endDate } = dateRange[0];
    //     dateRangeText = `${startDate?.toISOString().split("T")[0]} - ${endDate?.toISOString().split("T")[0]}`;
    // }

    return (
        <div className={"flex"}>
            <DataTableSearchBar filters={filters} setFilters={setFilters}/>

            {/*<span className={"flex items-center dark:text-white mr-3"}>{dateRangeText}</span>*/}
            <Button
                className={"font-bold"}
                variant={"tableExport"}
                icon={<img src={DownloadIcon} alt={"download"} />}
                onClick={exportCSV}
            >
                Export
            </Button>
        </div>
    );
};

const imageBodyTemplate = ({
    product,
    colVisMapper,
}: {
    product: any,
    colVisMapper: { [key: string]: string | ColumnVisMeta }
}) => {
    const imgData = colVisMapper[ColumnVisMeta.Image]
    const skuData = colVisMapper[ColumnVisMeta.Merge_toImage]

    return (
        <div className={"gap-x-4 text-center min-w-[6rem]"}>
            <div className={"bg-white mx-2 flex justify-center"}>
                <img src={`${product[imgData]}`} alt={`${product[imgData]}`}
                     className="w-[6rem] h-[4.1rem] object-scale-down shadow-2 border-round"/>
            </div>
            { skuData ? <span>{product[skuData]}</span> : null }
        </div>
    );
};

const customSortIcon = (options: any) => {
    if (options.field === options.sortField) {
        if (options.sortOrder > 0) return <DataTableSortAscendingIcon/>;
        else if (options.sortOrder < 0) return <DataTableSortDescendingIcon/>;
    }

    return <DataTableSortIcon/>;
}

export default PaginationTable;