import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {useAssemblyFunnel} from "@/features/assembly/api/get-assembly-funnel";
import {FunnelChart} from "@/components/ui/chart/funnel/funnel";
import {echartColors} from "@/styles/echart-styles";

export const AssemblyFunnel = ({
    assembly_item,
    dateRange,
    height = "21.4rem",
}: {
    assembly_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const assemblyFunnelQuery = useAssemblyFunnel({
        brand,
        assembly_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (assemblyFunnelQuery.isLoading) {
        return <div>Assembly Funnel Loading...</div>;
    }

    if (!assemblyFunnelQuery.data) return null;

    return (
        <FunnelChart
            title={"Ad Funnel"}
            series={assemblyFunnelQuery.data.map(row => [row.name, row.value])}
            height={height}
            colors={[
                echartColors.brown,
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}