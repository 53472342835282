import {useSales} from "@/features/sales/api/get-sales";
import SimpleCard, {
    CardVisMapperProps,
    CardVisMeta,
    OmitSubtitleProps
} from "@/components/ui/card/simple-card/simple-card";
import React from "react";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";

export const SalesCard = ({
    dateRange,
}: {
    dateRange: Range[];
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesQuery = useSales({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (salesQuery.isLoading) {
        return <div>Sales Card Loading...</div>;
    }

    if (!salesQuery.data) return null;

    const cardVisMapper: CardVisMapperProps = {
        "Sales": CardVisMeta.Money,
        "Units": CardVisMeta.Number,
        "Average Order Value": CardVisMeta.Money,
    }

    const omitSubtitleMapper: OmitSubtitleProps = {
        "Sales": true,
        "Units": true,
        "Average Order Value": true,
    }

    return (
        <SimpleCard
            data={salesQuery.data}
            dateRange={dateRange}
            cardVisMapper={cardVisMapper}
            omitSubtitleMapper={omitSubtitleMapper}
        >
        </SimpleCard>
    )
}