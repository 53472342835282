import * as React from "react";
import Echarts, {EchartProps, timeSeriesTooltipFormatter} from "@/components/ui/chart/echarts";
import {echartColors, echartColorsProducer} from "@/styles/echart-styles";
import {TimeValueLineData} from "@/components/ui/chart/line/one-axis-line";

export type multipleAxisLabel = {
    name: string;
    position: "left" | "right" | "top" | "bottom";
    offset?: number;
};

type MultipleAxisTimeValueLineProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string;
    labels: multipleAxisLabel[];
    series: TimeValueLineData[][];
    width?: string;
    height: string;
    colors?: string[];
}

const MultipleAxisTimeValueLineChart = React.forwardRef<HTMLDivElement, MultipleAxisTimeValueLineProps>(
    (
        {
            className,
            children,
            title,
            labels,
            series,
            width,
            height,
            colors = echartColorsProducer,
            ...props
        },
        ref
    ) => {
        const isDarkMode = document.documentElement.classList.contains('dark');
        const hasData = series.some(serie => serie && serie.length > 0);

        const line_graph: EchartProps["option"] = {
            color: colors,
            animation: true,
            grid: {
                left: "8%",
                right: "8%",
            },
            legend: hasData ? {
                data: labels,
                bottom: "15",
                borderRadius: 4,
                itemWidth: 13,
                itemHeight: 16,
                textStyle: {
                    color: isDarkMode ? echartColors.white : echartColors.black,
                    fontWeight: "bold",
                }
            }: {
                show: false,
            },
            title: {
                text: title,
                left: "20",
                top: "15",
                textStyle: {
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    fontSize: 14,
                }
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => timeSeriesTooltipFormatter({params})
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
                axisTick: {
                    show: false,
                    alignWithLabel: true
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    color: isDarkMode ? echartColors.greyLabel : echartColors.darkGreyLabel,
                    formatter: function (value: string | number | Date) {
                        const date = new Date(value);
                        return `${date.getMonth() + 1}/${date.getDate()}`;
                    },
                },
            },
            yAxis: labels.map((label, index) => {
                return (
                    {
                        type: "value",
                        position: label.position,
                        offset: label.offset,
                        alignTicks: true,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: colors[index],
                            },
                        },
                        axisLabel: {
                            formatter: ["Sales", "Spend"].includes(labels[index].name) ? "${value}" : "{value}",
                        },
                        splitLine: {
                            lineStyle: {
                                type: 'dashed',
                                color: isDarkMode ? echartColors.greyLine : echartColors.silverLine,
                                width: 1,
                            }
                        }
                    }
                );
            }),
            series: series.map((serie, index) => {
                return (
                    {
                        data: serie.map((row, index) => [row[0], row[1]]),
                        name: labels[index].name,
                        type: "line",
                        symbol: "none",
                        yAxisIndex: index,
                    }
                );
            }),
            graphic: hasData ? [] : [{
                type: 'text',
                left: 'center',
                top: 'middle',
                style: { text: "No data available", fontSize: 20, fill: "#999" },
            }],
        }

        return (
            <Echarts
                option={line_graph}
                width={width}
                height={height}
            />
        );
    }
);

export {
    MultipleAxisTimeValueLineChart
};