import * as React from "react";
import {usePurchaseItems} from "@/features/purchase/api/get-purchase-items";
import PaginationTable, {
    ColumnVisMapperProps,
    ColumnVisMeta
} from "@/components/ui/table/pagination-table/pagination-table";
import {useBrand} from "@/app/main-provider";
import {numberF} from "@/utils/formatter";


export const PurchaseItems = ({
    filter,
}: {
    filter: string;
}) => {
    const { brand } = useBrand();

    const purchaseItemsQuery = usePurchaseItems({
        brand: brand,
        selector: filter
    });

    if (purchaseItemsQuery.isLoading) {
        return <div>Purchase Table Loading...</div>;
    }

    if (!purchaseItemsQuery.data) return null;

    const visMapper: ColumnVisMapperProps = {
        ...purchaseItemsQuery.data.columns.reduce((acc: ColumnVisMapperProps, col) => {
            if (col.type?.toLowerCase() === "dynamic" && Object.keys(acc).length < 2){
                acc[col.name] = ColumnVisMeta.Highlight;
            }
            return acc;
        }, {})
    }

    return (
        <div>
            <PaginationTable
                data={purchaseItemsQuery.data.data.map((item: { [key: string]: any }) => ({
                    ...item,
                    ...purchaseItemsQuery.data.columns.reduce((acc: { [key: string]: any }, col) => {
                        if (col.type?.toLowerCase() === "dynamic"){
                            acc[col.name] = numberF(item[col.name]);
                        }
                        return acc;
                    }, {})
                }))}
                columns={purchaseItemsQuery.data.columns}
                initialFilters={{}}
                visMapper={visMapper}
            />
        </div>
    );
}