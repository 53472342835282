import * as React from "react";
import {cn} from "@/utils/cn";

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            ...props
        },
        ref,
    ) => (
        <div className={cn("bg-white dark:bg-rigleDark rounded-[14px] w-full pt-2.5 pb-5 px-5 flex flex-col gap-y-2", className)} {...props} >
        </div>
    )
);

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            children,
            ...props
        },
        ref
    ) => (
        <div className={cn("font-bold text-gray-500 dark:text-rigleDarkGrey flex justify-between items-center", className)} {...props} >
            {children}
        </div>
    )
);

const CardBody = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
    (
        {
            className,
            children,
            ...props
        },
        ref
    ) => (
        <div className={cn("dark:text-white flex flex-col gap-y-1", className)} {...props} >
            {children}
        </div>
    )
);

export { Card, CardHeader, CardBody };