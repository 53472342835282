import {useBrand} from "@/app/main-provider";
import {useAssemblyOrganicBar} from "@/features/assembly/api/get-assembly-organic-bar";
import {MultipleAxisTimeValueBarChart} from "@/components/ui/chart/bar/multiple-axis-bar";
import {echartColors} from "@/styles/echart-styles";

export const AssemblyOrganicBar = ({
    assembly_item,
    height = "20.8rem",
}: {
    assembly_item: string;
    height?: string;
}) => {
    const { brand } = useBrand();

    const assemblyOrganicBarQuery = useAssemblyOrganicBar({
        brand,
        assembly_item,
    });

    if (assemblyOrganicBarQuery.isLoading) {
        return <div>Assembly Organic Bar Loading...</div>;
    }

    if (!assemblyOrganicBarQuery.data) return null;

    return (
        <MultipleAxisTimeValueBarChart
            title={"Organic Summary"}
            labels={[
                { name: "Organic", position: "left" },
                { name: "Ad Sales", position: "right" },
            ]}
            series={[
                assemblyOrganicBarQuery.data.map((row) => [row.date, row.organic]),
                assemblyOrganicBarQuery.data.map((row) => [row.date, row.ad_sales]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
            stacked={true}
        />
    );
}