import {queryOptions, useQuery} from "@tanstack/react-query";

import {PurchaseChartResponse} from "@/types";
import {api} from "@/lib/api-client";
import {QueryConfig} from "@/lib/react-query";

export const getComponentPurchaseForecasting = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}): Promise<PurchaseChartResponse> => {
    return api.get(`/products/${component_item}/purchase-forecasting`, {
        params: {
            brand,
            component_item,
        },
    });
}

export const getComponentPurchaseForecastingQueryOptions = ({
    brand,
    component_item,
}: {
    brand: string;
    component_item: string;
}) => {
    return queryOptions({
        queryKey: ["component", "purchase-forecasting", brand, component_item],
        queryFn: () => getComponentPurchaseForecasting({ brand, component_item }),
    })
}

type UseComponentPurchaseForecastingOptions = {
    brand: string;
    component_item: string;
    queryConfig?: QueryConfig<typeof getComponentPurchaseForecastingQueryOptions>;
}

export const useComponentPurchaseForecasting = ({
    brand,
    component_item,
    queryConfig,
}: UseComponentPurchaseForecastingOptions) => {
    return useQuery({
        ...getComponentPurchaseForecastingQueryOptions({ brand, component_item }),
        ...queryConfig,
    })
}