import {useBrand} from "@/app/main-provider";
import {useAssembly} from "@/features/assembly/api/get-assembly";
import SimpleCard, {CardVisMapperProps, CardVisMeta} from "@/components/ui/card/simple-card/simple-card";

export const AssemblyCard = ({
    assembly_item,
}: {
    assembly_item: string;
}) => {
    const { brand } = useBrand();

    const assemblyCardQuery = useAssembly({
        brand,
        assembly_item,
    });

    if (assemblyCardQuery.isLoading) {
        return <div>Assembly Card Loading...</div>;
    }

    if (!assemblyCardQuery.data) return null;

    const cardVisMapper: CardVisMapperProps = {
        "Sales": CardVisMeta.Money,
        "Units": CardVisMeta.Number,
    }

    return (
        <SimpleCard
            data={assemblyCardQuery.data}
            cardVisMapper={cardVisMapper}
        />
    );
}