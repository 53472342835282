import {useBrand} from "@/app/main-provider";
import {useComponentPurchaseForecasting} from "@/features/component/api/get-component-purchase-forecasting";
import {MultipleAxisTimeValueBarChart} from "@/components/ui/chart/bar/multiple-axis-bar";
import {echartColors} from "@/styles/echart-styles";

export const ComponentPurchaseForecasting = ({
    component_item,
    height = "21.4rem"
}: {
    component_item: string;
    height?: string;
}) => {
    const { brand } = useBrand();

    const componentPurchaseForecastingQuery = useComponentPurchaseForecasting({
        brand: brand,
        component_item: component_item,
    });

    if (componentPurchaseForecastingQuery.isLoading) {
        return <div>Component Purchase Forecasting Loading...</div>;
    }

    if (!componentPurchaseForecastingQuery.data) return null;

    return (
        <MultipleAxisTimeValueBarChart
            title={"Purchase Forecast"}
            labels={[
                { name: "Amount", position: "left" },
                { name: "Quantity", position: "right" },
            ]}
            series={[
                componentPurchaseForecastingQuery.data.summary.map((row) => [row.time, row.amount]),
                componentPurchaseForecastingQuery.data.summary.map((row) => [row.time, row.quantity]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}