import * as React from "react";

import {Range} from "react-date-range";

import {useBrand} from "@/app/main-provider";
import {useSalesChart} from "@/features/sales/api/get-sales-chart";
import {echartColors} from "@/styles/echart-styles";
import {MultipleAxisTimeValueLineChart} from "@/components/ui/chart/line/multiple-axis-line";

export const SalesChart = ({
    dateRange,
    height = "18.5rem",
}: {
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const salesChartQuery = useSalesChart({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (salesChartQuery.isLoading) {
        return <div>Sales Chart Loading...</div>;
    }

    if (!salesChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueLineChart
            title={"Sales Summary"}
            labels={[
                { name: "Sales", position: "left" },
                { name: "Units", position: "right" },
            ]}
            series={[
                salesChartQuery.data.map(row => [row.date, row.sales]),
                salesChartQuery.data.map(row => [row.date, row.units]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}