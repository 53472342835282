import {useBrand} from "@/app/main-provider";
import {useAssemblyChart} from "@/features/assembly/api/get-assembly-chart";
import {Range} from "react-date-range";
import {MultipleAxisTimeValueLineChart} from "@/components/ui/chart/line/multiple-axis-line";
import {echartColors} from "@/styles/echart-styles";

export const AssemblyChart = ({
    assembly_item,
    dateRange,
    height = "21.4rem",
}: {
    assembly_item: string;
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const assemblyChartQuery = useAssemblyChart({
        brand,
        assembly_item,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (assemblyChartQuery.isLoading) {
        return <div>Assembly Charts Loading...</div>;
    }

    if (!assemblyChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueLineChart
            title={"Sales Summary"}
            labels={[
                { name: "Sales", position: "left" },
                { name: "Units", position: "right" },
            ]}
            series={[
                assemblyChartQuery.data.map(row => [row.date, row.sales]),
                assemblyChartQuery.data.map(row => [row.date, row.units])
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}