import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {useAdvertisingItems} from "@/features/advertising/api/get-advertising-items";
import PaginationTable, {
    ColumnVisMapperProps, ColumnVisMeta,
    CustomFilterMeta
} from "@/components/ui/table/pagination-table/pagination-table";
import {FilterMatchMode} from "primereact/api";
import {currencyF, numberF, percentF} from "@/utils/formatter";

export const AdvertisingItems = ({
    dateRange,
}: {
    dateRange: Range[];
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingItemsQuery = useAdvertisingItems({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    });

    if (advertisingItemsQuery.isLoading) {
        return <div>Advertising Items Loading...</div>;
    }

    if (!advertisingItemsQuery.data) return null;

    const initialFilters: CustomFilterMeta = {
        asin: { value: null, matchMode: FilterMatchMode.CONTAINS },
        vendor_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // For using custom filters, you must set FilterMatchMode.CUSTOM to matchMode.
        impressions: { value: null, matchMode: FilterMatchMode.CUSTOM },
        clicks: { value: null, matchMode: FilterMatchMode.CUSTOM },
        roas: { value: null, matchMode: FilterMatchMode.CUSTOM },
        acos: { value: null, matchMode: FilterMatchMode.CUSTOM },
        total_br_sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
        total_ad_sales: { value: null, matchMode: FilterMatchMode.CUSTOM },
        total_ad_spend: { value: null, matchMode: FilterMatchMode.CUSTOM },
    }

    const visMapper: ColumnVisMapperProps = {
        amz_link: ColumnVisMeta.Amz_link,
        asin: ColumnVisMeta.Merge_toAmzLink,
        detail_link: ColumnVisMeta.Detail_link,
    }

    return (
        <div>
            <PaginationTable
                data={advertisingItemsQuery.data.data.map(item => ({
                    ...item,
                    impressions: numberF(item.impressions),
                    clicks: numberF(item.clicks),
                    roas: numberF(item.roas),
                    acos: percentF(item.acos),
                    total_ad_spend: currencyF(item.total_ad_spend),
                    total_ad_sales: currencyF(item.total_ad_sales),
                    total_br_sales: currencyF(item.total_br_sales),
                }))}
                columns={advertisingItemsQuery.data.columns}
                initialFilters={initialFilters}
                visMapper={visMapper}
                dateRange={dateRange}
            />
        </div>
    );
}