import {useBrand} from "@/app/main-provider";
import {useComponentOpenPo} from "@/features/component/api/get-component-open-po";
import PaginationTable from "@/components/ui/table/pagination-table/pagination-table";
import {currencyF, numberF} from "@/utils/formatter";

export const ComponentOpenPo = ({
    component_item,
}: {
    component_item: string;
}) => {
    const { brand } = useBrand();

    const componentOpenPoQuery = useComponentOpenPo({
        brand: brand,
        component_item: component_item,
    });

    if (componentOpenPoQuery.isLoading) {
        return <div>Component Open Po Loading...</div>;
    }

    if (!componentOpenPoQuery.data) return null;

    return (
        <PaginationTable
            data={componentOpenPoQuery.data.data.map(item => ({
                ...item,
                quantity: numberF(item.quantity),
                amount: currencyF(item.amount)
            }))}
            columns={componentOpenPoQuery.data.columns}
            initialFilters={{}}
            visMapper={{}}
        />
    );
}