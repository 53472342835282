import {useComponent} from "@/features/component/api/get-component";
import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import SimpleCard, {CardVisMapperProps, CardVisMeta} from "@/components/ui/card/simple-card/simple-card";
import DateRangeDropdown from "@/components/ui/dropdown/date-range-dropdown/date-range-dropdown";
import React from "react";

export const ComponentCard = ({
    component_item,
    dateRange,
    setDateRange,
}: {
    component_item: string;
    dateRange: Range[];
    setDateRange: (dateRange: Range[]) => void;
}) => {
    const { brand } = useBrand();

    const componentCardQuery = useComponent({
        brand: brand,
        component_item: component_item
    });

    if (componentCardQuery.isLoading) {
        return <div>Component Card Loading...</div>;
    }

    if (!componentCardQuery.data) return null;

    const cardVisMapper: CardVisMapperProps = {
        "Sales": CardVisMeta.Money,
        "Units": CardVisMeta.Number,
    }

    return (
        <>
            <div className={"flex items-center justify-between sticky top-16 z-20"}>
                <span className={"font-bold text-2xl mr-auto dark:text-white"}>{componentCardQuery.data.title}</span>
                <DateRangeDropdown
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
            </div>
            <SimpleCard
                data={componentCardQuery.data}
                cardVisMapper={cardVisMapper}
            >
            </SimpleCard>
        </>

    );
}