import * as React from 'react';

import {useBrand} from "@/app/main-provider";
import {useSalesAnnualChart} from "@/features/sales/api/get-sales-annual-chart";
import {echartColors} from "@/styles/echart-styles";
import {MultipleAxisTimeValueBarChart} from "@/components/ui/chart/bar/multiple-axis-bar";

export const SalesAnnualChart = ({
    height = "18.5rem",
}: {
    height?: string;
}) => {
    const { brand } = useBrand();

    const salesAnnualChartQuery = useSalesAnnualChart({
        brand: brand,
    })

    if (salesAnnualChartQuery.isLoading) {
        return <div>Loading...</div>;
    }

    if (!salesAnnualChartQuery.data) return null;

    return (
        <MultipleAxisTimeValueBarChart
            title={"Annual Sales Performance"}
            labels={[
                { name: "Sales", position: "left" },
                { name: "Units", position: "right" },
            ]}
            series={[
                salesAnnualChartQuery.data.map(row => [row.date, row.sales]),
                salesAnnualChartQuery.data.map(row => [row.date, row.units]),
            ]}
            height={height}
            colors={[
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}