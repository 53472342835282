import {Range} from "react-date-range";
import {useBrand} from "@/app/main-provider";
import {useAdvertisingFunnel} from "@/features/advertising/api/get-advertising-funnel";
import {FunnelChart} from "@/components/ui/chart/funnel/funnel";
import {echartColors} from "@/styles/echart-styles";

export const AdvertisingFunnel = ({
    dateRange,
    height = "18.5rem",
}: {
    dateRange: Range[];
    height?: string;
}) => {
    const { brand } = useBrand();
    const { startDate, endDate } = dateRange[0];

    const advertisingFunnelQuery = useAdvertisingFunnel({
        brand: brand,
        period: "Custom",
        startDate: startDate?.toISOString().split("T")[0],
        endDate: endDate?.toISOString().split("T")[0],
    })

    if (advertisingFunnelQuery.isLoading) {
        return <div>Advertising Funnel Loading...</div>;
    }

    if (!advertisingFunnelQuery.data) return null;

    return (
        <FunnelChart
            title={"Ad Funnel"}
            series={advertisingFunnelQuery.data.map(row => [row.name, row.value])}
            height={height}
            colors={[
                echartColors.brown,
                echartColors.green,
                echartColors.yellow,
            ]}
        />
    );
}