import {cva, VariantProps} from "class-variance-authority";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem, DropdownMenuItemCheck,
    DropdownTrigger
} from "@/components/ui/dropdown/dropdown";
import {cn} from "@/utils/cn";

const dropdownVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "bg-white dark:bg-rigleDark",
            },
            size: {
                default: '',
            }
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    },
);

export type SimpleDropdownProps = React.HTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof dropdownVariants> & {
    filter: string;
    filterOptions: string[];
    setFilter: (filter: string) => void;
    localStorageKey?: string;
}

const SimpleDropdown = React.forwardRef<HTMLButtonElement, SimpleDropdownProps>(
    (
        {
            className,
            variant,
            size,
            children,
            filter,
            filterOptions,
            setFilter,
            localStorageKey,
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const dropdownRef = useRef<HTMLDivElement>(null);

        const handleSelect = (event: any) => {
            if (localStorageKey && event.target.value !== undefined) {
                localStorage.setItem(localStorageKey, event.target.value);
            }

            setFilter(event.target.value);
        }

        const toggleDropdown = () => {
            setIsOpen(!isOpen);
        };

        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <DropdownMenu ref={dropdownRef} className={cn({ variant, size, className })}>
                <DropdownTrigger
                    className={cn("inline-flex items-center rounded-[5px] dark:text-white bg-white dark:bg-rigleDark border-2 border-gray-200 dark:border-rigleGreyLine", className)}
                    aria-expanded={isOpen}
                    isOpen={isOpen}
                    onClick={toggleDropdown}
                >
                    {filter}
                </DropdownTrigger>

                {
                    isOpen ? (
                        <DropdownMenuContent className={cn(`max-h-[40rem] overflow-y-scroll`, dropdownVariants({
                            variant,
                            size,
                            className
                        }))}>
                            {filterOptions?.map((value, index) => (
                                <div className={"flex items-center dark:text-white"}>
                                    <DropdownMenuItem className={value === filter ? "font-bold" : ""}
                                                      key={value + index} value={value} onClick={handleSelect}>
                                        {value}
                                    </DropdownMenuItem>
                                    {
                                        value === filter ? <DropdownMenuItemCheck/> : null
                                    }
                                </div>
                            ))}
                        </DropdownMenuContent>
                    ) : null
                }

            </DropdownMenu>
        );
    }
);

export default SimpleDropdown;