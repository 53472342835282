import {useUser} from "@/lib/auth";
import {useAuthorization} from "@/lib/authorization";
import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import SimpleDropdown from "@/components/ui/dropdown/simple-dropdown/simple-dropdown";
import UserDropdown from "@/components/ui/dropdown/user-dropdown/user-dropdown";
import {useBrand} from "@/app/main-provider";
import SideBar from "@/components/ui/side-bar/side-bar";

export function DashboardLayout({ children }: { children: React.ReactNode }) {
    const user = useUser();
    const { checkAccess } = useAuthorization();
    const navigate = useNavigate();
    const navigation = [
        { name: "Dashboard", to: "." },
        { name: "Sales", to: "./sales" },
        { name: "Products", to: "./products" },
        { name: "Advertising", to: "./advertising" },
        { name: "Analytics", to: "#",
            subTabs: [
                { name: "Purchase Forecast", to: "./purchase" },
                { name: "Brand Analytics", to: "./analytics" },
            ] },
        { name: "Settings", to: "#", isUser: true,
            subTabs: [
                { name: "Profile", to: "./profile" },
                { name: "Users & Permissions", to: "./users" },
            ] },
    ]

    const { brand, setBrand } = useBrand();
    useEffect(() => {
        if (user.data && brand === "") {
            setBrand(user.data.brand[0]);
        }
    }, [user.data, setBrand, brand]);

    // dark mode
    document.documentElement.classList.add("dark");

    return (
        <div className={"flex h-screen overflow-hidden dark:bg-black"}>
            <SideBar navigation={navigation} />
            <div className={"flex-1 flex flex-col overflow-x-hidden"}>
                <header className={"flex items-center justify-between sticky top-0 left-0 right-0 z-30 h-16 w-full px-9 py-3 dark:bg-rigleDark"}>
                    {
                        !user || !user.data ? null :
                            <div className={"flex items-center justify-between w-full h-full"}>
                                <SimpleDropdown
                                    filter={brand}
                                    filterOptions={user.data.brand}
                                    setFilter={setBrand}
                                    localStorageKey={"brand"}
                                    className={"w-72"}
                                />
                                <UserDropdown/>
                            </div>
                    }
                </header>
                <main className={"flex-1 flex flex-col gap-y-4 h-full w-full bg-[#F2F2F2] dark:bg-black p-6"}>
                    {children}
                    <br/>
                </main>
            </div>
        </div>
    );
}