import {useBrand} from "@/app/main-provider";
import {useAssemblyRepeatCustomer} from "@/features/assembly/api/get-assembly-repeat-customer";
import {DonutChart} from "@/components/ui/chart/pie/pie";

export const AssemblyRepeatCustomer = ({
    assembly_item,
    height = "18.5rem",
}: {
    assembly_item: string;
    height?: string;
}) => {
    const { brand } = useBrand();

    const assemblyRepeatCustomerQuery = useAssemblyRepeatCustomer({
        brand,
        assembly_item,
    });

    if (assemblyRepeatCustomerQuery.isLoading) {
        return <div>Assembly Repeat Customer Loading...</div>;
    }

    if (!assemblyRepeatCustomerQuery.data) return null;

    return (
        <DonutChart
            title={"New vs Repeat Customers"}
            labels={["New Customers", "Repeat Customers"]}
            series={assemblyRepeatCustomerQuery.data.map(row => [row.name, row.value])}
            height={height}
            center={['35%', '55%']}
        />
    );
}